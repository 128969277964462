/**
 * 发送form-data  的设置
 */
const formDataConfig = {
  headers: {
    'Content-Type': 'multipart/form-data'
  }
}

/**
 * 发送json-data 的设置
 */
const jsonDataConfig = {
  headers: {
    'Content-Type': 'application/json; charset=utf-8'
  }
}

/**
 * 图片歌曲服务器地址
 */

// const mediaIp = 'http://localhost:8088'
const mediaIp = 'https://server.superlwb0608.top/'

/**
 * api 服务器地址
 */

const api = 'https://server.superlwb0608.top'

export { formDataConfig, jsonDataConfig, mediaIp, api }
