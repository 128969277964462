import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import ElementPlus from 'element-plus'
import axios from 'axios'
import { api } from './global/global'

// markdown-view
import VMdPreviewHtml from '@kangc/v-md-editor/lib/preview-html'
import '@kangc/v-md-editor/lib/style/preview-html.css'

// 引入使用主题的样式
import '@kangc/v-md-editor/lib/theme/style/vuepress.css'

import * as ElementPlusIconsVue from '@element-plus/icons-vue'

// axios 配置生产环境
const instance = axios.create({
  // 基础URL，在非生产环境中会被拦截器修改
  baseURL: api,
  timeout: 10000 // 请求超时时间
})

// 请求拦截器
instance.interceptors.request.use((config) => {
  // 在非生产环境中修改请求的URL
  // if (process.env.NODE_ENV !== 'production') {
  //   config.url = 'https://server.superlwb0608.top' + config.url
  // }
  return config
})

const app = createApp(App)

// 注册icon
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
  app.component(key, component)
}

// 设置全局axios

app.config.globalProperties.$axios = axios

app.use(router)
app.use(ElementPlus)
app.use(VMdPreviewHtml)
app.mount('#app')
