import "core-js/modules/es.array.push.js";
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, unref as _unref, normalizeClass as _normalizeClass, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-5ebab17f"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "max-width swiper"
};
const _hoisted_2 = {
  class: "image"
};
const _hoisted_3 = {
  class: "count"
};
const _hoisted_4 = ["src"];
const _hoisted_5 = {
  class: "min-title"
};
const _hoisted_6 = {
  class: "min-date"
};
const _hoisted_7 = {
  class: "info"
};
const _hoisted_8 = {
  class: "title"
};
const _hoisted_9 = {
  class: "desc"
};
const _hoisted_10 = {
  class: "date"
};
import { ElMessage } from 'element-plus';
import { onMounted, ref, getCurrentInstance } from 'vue';
import { mediaIp, api } from '@/global/global';
import formatDate from '@/utils/formatDate';
import router from '@/router';
export default {
  __name: 'Swiper',
  setup(__props) {
    const axios = getCurrentInstance().proxy.$axios;

    // 图片元素
    const swiperList = ref([]);
    const changeSwiper = e => {};
    const getSwiperList = async () => {
      const {
        data: res
      } = await axios.get(api + '/blog/getSwiperArticleList');
      if (res.status != 200) {
        ElMessage.error(res.message);
        return;
      }
      swiperList.value = res.data;
    };
    const gotoArticle = id => {
      router.push({
        path: '/article',
        query: {
          id: id
        }
      });
    };
    onMounted(() => {
      getSwiperList();
    });
    return (_ctx, _cache) => {
      const _component_AlarmClock = _resolveComponent("AlarmClock");
      const _component_el_icon = _resolveComponent("el-icon");
      const _component_el_carousel_item = _resolveComponent("el-carousel-item");
      const _component_el_carousel = _resolveComponent("el-carousel");
      return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_el_carousel, {
        trigger: "click",
        height: "150px",
        onChange: changeSwiper
      }, {
        default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(swiperList.value, (item, index) => {
          return _openBlock(), _createBlock(_component_el_carousel_item, {
            key: item,
            onClick: $event => gotoArticle(item.id)
          }, {
            default: _withCtx(() => [_createElementVNode("div", _hoisted_2, [_createElementVNode("div", _hoisted_3, _toDisplayString(index + 1) + " / " + _toDisplayString(swiperList.value.length), 1), _createElementVNode("img", {
              src: _unref(mediaIp) + item.coverUrl,
              alt: ""
            }, null, 8, _hoisted_4), _createElementVNode("div", {
              class: _normalizeClass(['min-info', item.showTips ? 'animate' : ''])
            }, [_createElementVNode("div", _hoisted_5, _toDisplayString(item.title), 1), _createElementVNode("div", _hoisted_6, _toDisplayString(_unref(formatDate)(item.date)), 1)], 2), _createElementVNode("div", _hoisted_7, [_createElementVNode("div", _hoisted_8, "《" + _toDisplayString(item.title) + "》", 1), _createElementVNode("div", _hoisted_9, _toDisplayString(item.desc), 1), _createElementVNode("div", _hoisted_10, [_createVNode(_component_el_icon, null, {
              default: _withCtx(() => [_createVNode(_component_AlarmClock)]),
              _: 1
            }), _createTextVNode(" " + _toDisplayString(_unref(formatDate)(item.date)), 1)])])])]),
            _: 2
          }, 1032, ["onClick"]);
        }), 128))]),
        _: 1
      })]);
    };
  }
};